@import '~@angular/material/theming';

@include mat-core();
$primary: mat-palette($mat-indigo);
$accent: mat-palette($mat-pink, A200, A100, A400);
$warn: mat-palette($mat-red);
$theme: mat-light-theme($primary, $accent, $warn); // Or mat-dark-theme

@include angular-material-theme($theme);

.dark-theme {
    color: $light-primary-text;
    $dark-primary: mat-palette($mat-light-blue);
    $dark-accent: mat-palette($mat-amber, A200, A100, A400);
    $dark-warn: mat-palette($mat-orange);
    $dark-theme: mat-dark-theme($dark-primary, $dark-accent, $dark-warn);

    @include angular-material-theme($dark-theme);
}
