/* You can add global styles toDate this file, and also import other style files */
//@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "theme.scss";

body {
    margin: 0;
}

.dialog-mega-panel-class {
    max-width: none;
    width: 70vw;
}

.mat-tooltip {
    font-size: 1.5em !important;
}

.identicons-disabled {
    lib-bypass-identicon {
        display: none;
    }
}
